/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import SEO from "src/components/seo"
import Footer from "src/components/Footer/Footer"
import PriceTable from "src/components/PriceTable/PriceTable"

import FAQ from "src/components/FAQ/FAQ"
import DownloadForm from "src/components/DownloadForm/DownloadForm"
import useTranslation from "src/hooks/useTranslation"

import AppleLogo from "src/assets/brand-logos/apple.svg"
import TwitterLogo from "src/assets/brand-logos/twitter.svg"
import NikeLogo from "src/assets/brand-logos/nike.svg"
import UberLogo from "src/assets/brand-logos/uber.svg"
import AtlassianLogo from "src/assets/brand-logos/atlassian.svg"

import "./pricing.scss"

const Pricing = ({ location }) => {
  const { t } = useTranslation({location});

  return (
    <div className="pricing text-justify">
      <SEO title="Pricing - Stack" description="Checkout our prices. Stack is a smart browser for internet multitaskers" />

      <div className="container container--sm text-left text-md-center">
        <h1 className="about-us__title mb-0 pb-0 border-0">Pricing</h1>
      </div>

      <div className="container mb-3 mb-md-5">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="mt-5 d-flex">
              <PriceTable
                showFree
                hideAngels
                noGap={true}
                location={location}
                hideLifetime
                dominateYearly
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container container--lg my-3 my-md-5">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="pricing__brand-logos">
              <div className="pricing__brand-logos__items">
                <img src={AppleLogo} alt="" className="pricing__brand-logos__logo" />
                <img src={TwitterLogo} alt="" className="pricing__brand-logos__logo" />
                <img src={AtlassianLogo} alt="" className="pricing__brand-logos__logo" />
                <img src={UberLogo} alt="" className="pricing__brand-logos__logo" />
                <img src={NikeLogo} alt="" className="pricing__brand-logos__logo" />
              </div>
              <p className="pricing__brand-logos__title">
                {/* They also Stack like a PRO{" "} */}
                Stackers can be found in these companies too
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="pricing__download">
          <h2>Still using a browser?</h2>
          <DownloadForm t={t} text="Download Stack" />
        </div>
      </div>

      <div className="container my-3 my-md-5">
        <div className="row">
          <div className="col-12">
            <hr className="mb-5 mt-2" style={{width: '150px'}} />

            <div className="text-left">
              <h1 className="about-us__title pricing__faq-title border-0 text-left mb-0 mb-md-0 pb-0 pb-md-3">Frequently Asked Questions</h1>
            </div>
            <FAQ />
          </div>
        </div>
      </div>

      <hr className="mt-5 mb-0" />

      <Footer />
    </div>
  )
}

export default Pricing
