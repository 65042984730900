import React from "react"
import Collapse from "src/components/Collapse/Collapse"
import "./faq.scss"

import usePlans from "src/hooks/usePlans"

export default function FAQ({ expanded }) {
  const { plans } = usePlans()
  return (
    <div className="faq">
      <Collapse title="Why shall I upgrade to PRO?" expanded={expanded}>
        <p>
          If you feel like organizing your web is important and would like to
          streamline your workflow even further, Stack PRO is for you!
        </p>
      </Collapse>
      <Collapse title="What happens after I purchase PRO?" expanded={expanded}>
        <p>
          Your PRO license will be linked to the email used to purchase it. If
          you are already authorized in Stack with your email, use the same
          email to purchase PRO. Otherwise, sign in to Stack with the email used
          to purchase PRO and you’re all set!
        </p>
      </Collapse>
      <Collapse
        title="What payment methods do you support?"
        expanded={expanded}
      >
        <p>
          We use Stripe as our payment gateway supporting Apple Pay,
          Google pay and most card payments.
        </p>
      </Collapse>
      <Collapse title="How can I manage my subscription?" expanded={expanded}>
        <p>
          You can upgrade or change your subscription from the Membership
          section of your profile in Stack software.
        </p>
      </Collapse>
      <Collapse title="What is your refund policy?" expanded={expanded}>
        <p>
          If you are not 100% satisfied with your purchase, you can request a
          refund. Different refund terms apply to different products and
          packages:
        </p>
        <ol>
          <li>
            Stack PRO monthly subscription - you can request a refund within 7
            days after your first payment for this package has been made. The
            refund does not apply to the payments for the second and other
            consecutive months of Stack PRO.
          </li>
          <li>
            Stack PRO annual subscription - you can request a refund within 14
            days after your first payment for this package has been made. This
            also applies to every consecutive payment for this package.
          </li>
          <li>
            There is no refund for the Stack PRO Lifetime Deals!
          </li>
        </ol>
      </Collapse>
    </div>
  )
}
