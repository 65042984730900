import React, { useState } from 'react'
import './collapse.scss'

import { FaChevronRight } from 'react-icons/fa'

export default function Collapse({ children, title, expanded: isExpanded }) {
  const [expanded, setExpanded] = useState(isExpanded)

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  return (
    <div className={`s-collapse ${expanded ? 's-collapse--expanded' : ''}`}>
      <h2 className="s-collapse__title uppercase-headline" onClick={toggleExpanded}>
        <FaChevronRight className="s-collapse__chevron" />
        {title}
      </h2>
      {expanded && <div className="s-collapse__content">{children}</div>}
    </div>
  )
}
