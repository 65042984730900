import { useState, useEffect } from 'react'
import axios from "axios";

function usePlans() {
  const [plans, setPlans] = useState()

  useEffect(() => {
    ;(async () => {
      const { data } = await axios.get(
        `${process.env.GATSBY_SERVER_URL}/pro/plans`
      )
      setPlans(data.data)
    })()
  }, [])

  return {plans}
}

export default usePlans
