import React, { useState, useEffect } from "react"
import axios from "axios"
import { FiCheck, FiX } from "react-icons/fi"
import useTranslation from "src/hooks/useTranslation"

import "./price-table.scss"
import "../../assets/style.scss"

const _PLANS = {
  free: {
    title: "forever",
    _id: "free",
    features: [
      { name: "Unlimited parallel cards" },
      { name: "Unlimited stacks" },
      { name: "3 free Spaces" },
      { name: "Multiple Accounts" },
      { name: "Desktop Notifications" },
      { name: "Synchronization" },
      { name: "Cross-Platform Support" },
      { name: "Dark theme" },
      { name: "Chrome Extensions" },
      { name: 'Built-in Ad & Trackers Blocker' },
    ],
  },
  monthly: {
    title: "monthly",
    features: [
      { name: "Everything in Free" },
      { name: "Auto-refresher", url: "https://stackbrowser.com/feature/auto-refresh" },
      { name: "Unlimited Spaces", url: "https://stackbrowser.com/feature/spaces" },
    ],
  },
  yearly: {
    title: "yearly",
    detailsUrl:
      "https://getstack.app/blog/how-to-use-stack-like-a-true-stacker",
    features: [
      { name: "Everything in Free" },
      { name: "Auto-refresher", url: "https://stackbrowser.com/feature/auto-refresh" },
      { name: "Unlimited Spaces", url: "https://stackbrowser.com/feature/spaces" },
    ],
  },
  lifetime: {
    title: "lifetime",
    dominant: false,
    limit: true,
    oneTime: true,
    oldPrice: "$149",
    detailsUrl:
      "https://getstack.app/blog/how-to-use-stack-like-a-true-stacker",
    features: [
      { name: "Everything in Free" },
      { name: "Auto-refresher", url: "https://stackbrowser.com/feature/auto-refresh" },
      { name: "Unlimited Spaces", url: "https://stackbrowser.com/feature/spaces" },
      { name: "Become", type: "separator" },
      { name: "Stack Angel" },
    ],
  },
  angel: {
    _id: "contribute",
    oneOff: true,
    oneTime: true,
    features: [{ name: "Stack Angel" }],
  },
}

const Plan = ({
  plan,
  dominant,
  currency,
  price,
  title,
  features,
  limit,
  sold,
  oldPrice,
  oneTime,
  oneOff,
  onSelect,
  selected,
  onInput,
  amount,
}) => {
  const [oneOffValue, setOneOffValue] = useState("")

  const isFree = plan?._id === "free"

  return (
    <div
      className={`
        price-table-plan
        ${dominant ? "price-table-plan--dominant" : ""}
      `}
    >
      {!oneOff && (
        <h1 className="price-table-plan__price">
          {isFree ? (
            <span>FREE</span>
          ) : (
            <span>
              {currency === "＄" ? "$" : currency}
              {price}
              {oldPrice && (
                <div className="price-table-plan__old-price">
                  {" "}
                  {currency}
                  {oldPrice}{" "}
                </div>
              )}
            </span>
          )}
        </h1>
      )}

      {oneOff && (
        <div className="price-table-plan__one-off">
          <p>Become Stack Angel with one-off contribution</p>
          <div
            className={`price-table-plan__input ${
              oneOffValue ? "price-table-plan__input--has-value" : ""
            }`}
          >
            <input
              onInput={e => {
                onInput(e)
                setOneOffValue(e.target.value)
              }}
              placeholder="Enter the amount"
              type="text"
              value={amount}
            />
            <span className="price-table-plan__prefix">
              {currency === "＄" ? "$" : currency}
            </span>
          </div>
        </div>
      )}

      {title && <h1 className="price-table-plan__title"> {title} </h1>}

      {!!limit && (
        <p className="price-table-plan__remaining">
          {" "}
          Sold Out
        </p>
      )}

      <ul className="price-table-plan__features">
        {features.map(feature => (
          <li
            key={feature.name}
            className={`
              price-table-plan__feature
              ${
                feature.type === "separator"
                  ? "price-table-plan__feature--separator"
                  : ""
              }
              ${feature.disabled ? "price-table-plan__feature--disabled" : ""}
            `}
          >
            <span className="price-table-plan__feature-title">
              {!feature.disabled && feature.type !== "separator" && (
                <FiCheck
                  style={{
                    fontSize: 18,
                    marginRight: 5,
                    marginTop: -2,
                    color: "#80ba27",
                  }}
                />
              )}
              {feature.disabled && <FiX style={{ marginRight: 5 }} />}
              {feature.url ? (<a href={feature.url} target="_blank">{feature.name}</a>) : feature.name}
              {feature.soon && (
                <span className="price-table-plan__feature-soon"> coming </span>
              )}
            </span>
            {feature.beta && <span className="price-table-plan__feature__beta">beta</span>}
          </li>
        ))}
      </ul>
    </div>
  )
}

const PriceTable = ({ page, noGap, hideAngels = false, showFree = false, location, hideLifetime = false, dominateYearly = false }) => {
  const [plans, setPlans] = useState({ monthly: {}, yearly: {}, lifetime: {} })
  const [paymentType, setPaymentType] = useState()
  const [selectedPlan, setSelectedPlan] = useState({})
  const [amount, setAmount] = useState()
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [couponCode, setCouponCode] = useState("")
  const [isDetailsFormVisible, setIsDetailsFormVisible] = useState(false)
  const { t } = useTranslation({ location })

  const fetchPlans = async () => {
    const { data } = await axios.get(
      `${process.env.GATSBY_SERVER_URL}/pro/plans`
    )
    setPlans(data.data)
  }

  const injectStripe = () => {
    import("src/utils/helpers").then(({ getWindow }) => {
      var window = getWindow()

      const script = window.document.createElement("script")
      script.src = "https://js.stripe.com/v3/"
      script.async = true
      script.onload = () => {
        window.stripe = window.Stripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)
      }

      window.document.body.appendChild(script)
    })
  }

  const setUserFromStack = async () => {
    try {
      const stackUser = await window.Stack.Auth.getCurrentUser()
      setName(stackUser.name)
      setEmail(stackUser.email)
    } catch (e) {}
  }

  useEffect(() => {
    fetchPlans()
    injectStripe()
    setUserFromStack()
  }, [])

  const checkout = async e => {
    e.preventDefault()

    const { data } = await axios.post(
      `${process.env.GATSBY_SERVER_URL}/support/checkout`,
      {
        amount,
        email,
        name,
        paymentType,
        planId: selectedPlan?.stripePlanId,
        planType: selectedPlan?.type,
        currency: plans.monthly?.currency,
        currencySymbol: plans.monthly?.currencySymbol,
        couponCode,
        page,
      }
    )

    window.stripe
      .redirectToCheckout({ sessionId: data.data.sessionId })
      .then(console.log)
  }

  const onSelect = plan => {
    setSelectedPlan(plan)
    if (plan._id !== "contribute") {
      setPaymentType("buy-plan")
    } else {
      setPaymentType("support")
    }
    setIsDetailsFormVisible(true)
  }
  const onInput = e => {
    setAmount((e.target.value || "").replace(/[^0-9]/g, ""))
  }

  return (
    <div className="price-table-wrapper">
      <div
        className={`
      price-table
      ${noGap ? "price-table--no-gap" : ""}
      ${hideLifetime ? "price-table--3" : ""}
    `}
      >
        {showFree && (
          <Plan
            plan={_PLANS.free}
            currency={plans?.lifetime.currencySymbol}
            title={_PLANS.free.title}
            features={_PLANS.free.features}
            oneTime={_PLANS.free.oneTime}
            oneOff={_PLANS.free.oneOff}
            onInput={onInput}
            onSelect={onSelect}
            amount={amount}
            selected={selectedPlan._id === _PLANS.angel._id}
          />
        )}
        <Plan
          plan={plans?.monthly}
          price={plans?.monthly.price}
          currency={plans?.monthly.currencySymbol}
          title={_PLANS.monthly.title}
          features={_PLANS.monthly.features}
          onSelect={onSelect}
          selected={selectedPlan._id === plans?.monthly._id}
        />
        <Plan
          plan={plans?.yearly}
          price={plans?.yearly.price}
          currency={plans?.yearly.currencySymbol}
          title={_PLANS.yearly.title}
          dominant={dominateYearly}
          features={_PLANS.yearly.features}
          onSelect={onSelect}
          selected={selectedPlan._id === plans?.yearly._id}
        />
        {!hideLifetime && (
          <Plan
            plan={plans?.lifetime}
            price={plans?.lifetime.price}
            currency={plans?.lifetime.currencySymbol}
            title={_PLANS.lifetime.title}
            features={_PLANS.lifetime.features}
            dominant={_PLANS.lifetime.dominant}
            limit={plans?.lifetime.limit}
            sold={plans?.lifetime.sold}
            oldPrice={plans?.lifetime.oldPrice}
            oneTime={_PLANS.lifetime.oneTime}
            oneOff={_PLANS.lifetime.oneOff}
            onSelect={onSelect}
            selected={selectedPlan._id === plans?.lifetime._id}
          />
        )}
        {!hideAngels && (
          <Plan
            plan={_PLANS.angel}
            currency={plans?.lifetime.currencySymbol}
            title={_PLANS.angel.title}
            features={_PLANS.angel.features}
            oneTime={_PLANS.angel.oneTime}
            oneOff={_PLANS.angel.oneOff}
            onInput={onInput}
            onSelect={onSelect}
            amount={amount}
            selected={selectedPlan._id === _PLANS.angel._id}
          />
        )}
      </div>

      {isDetailsFormVisible && (
        <div className="price-form-modal">
          <form className="price-form price-form--animated" onSubmit={checkout}>
            <p className="mt-3 mb-3 price-form__label d-flex align-items-center">
              Enter your Stack email
              <button
                className="se-button ml-auto"
                type="button"
                onClick={() => setIsDetailsFormVisible(false)}
              >
                X
              </button>
            </p>

            <div className="price-form__group mt-3">
              <input
                type="email"
                value={email || ""}
                onChange={e => setEmail(e.target.value)}
                placeholder={t("support.email")}
                required
              />

              <input
                className="mb-1"
                type="text"
                value={name || ""}
                onChange={e => setName(e.target.value)}
                placeholder={t("support.name")}
              />
            </div>

            <small id="emailHelp" className="form-text text-muted mb-2">
              Leave empty if you want to be anonymous Angel
            </small>

            {paymentType === "buy-plan" &&
              ["monthly", "yearly"].includes(selectedPlan?.type) && (
                <div>
                  <div className="price-form__group mt-3">
                    <input
                      className="mb-1"
                      type="text"
                      value={couponCode || ""}
                      onChange={e => setCouponCode(e.target.value)}
                      placeholder={"Coupon Code"}
                    />
                  </div>

                  <small id="emailHelp" className="form-text text-muted mb-2">
                    Enter your coupon code and it will be automatically applied
                    to your subscription (if valid) on checkout page
                  </small>
                </div>
              )}

            <button
              type="submit"
              className="se-button se-button--indigo mt-2 donate-button"
              disabled={selectedPlan?._id === "contribute" && !amount}
            >
              Pay
            </button>
          </form>
        </div>
      )}
    </div>
  )
}

export default PriceTable
